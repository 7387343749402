import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  switchMap,
  catchError,
  of,
  map,
  tap,
  finalize,
  combineLatest,
} from 'rxjs';
import * as AuthActions from './auth.actions';
import {
  addUserToResourceFn,
  addUserToResourcesFn,
  getRolesFn,
  getUserRoleResourcesFn,
  removeUserFromResourceFn,
  setUserRoleResourcesFn,
} from '../services/roles.service';
import { createBoardSuccess } from '@feature-vote/boards/data-access';
import {
  openFailureSnackBarFn,
  openSuccessSnackBarFn,
} from '@feature-vote/shared/util';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private readonly getRoles$ = getRolesFn();
  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadRoles),
      switchMap(() =>
        this.getRoles$().pipe(
          map(({ data }) => AuthActions.loadRolesSuccess({ roles: data }))
        )
      ),
      catchError(error => {
        console.error('Error', error);
        return of(AuthActions.loadRolesFailure({ error }));
      })
    )
  );
  private readonly getUserRoleResources$ = getUserRoleResourcesFn();
  loadUserRoleResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserRoleResources, createBoardSuccess),
      switchMap(() =>
        this.getUserRoleResources$().pipe(
          map(({ data }) =>
            AuthActions.loadUserRoleResourcesSuccess({
              userRoleResources: data,
            })
          )
        )
      ),
      catchError(error => {
        console.error('Error', error);
        return of(AuthActions.loadUserRoleResourcesFailure({ error }));
      })
    )
  );
  private readonly setUserRoleResources$ = setUserRoleResourcesFn();
  createUserRoleResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setUserRoleResources),
      switchMap(({ userRoleResources }) =>
        this.setUserRoleResources$(userRoleResources).pipe(
          map(({ data }) =>
            AuthActions.setUserRoleResourcesSuccess({ userRoleResources: data })
          )
        )
      ),
      catchError(error => {
        console.error('Error', error);
        return of(AuthActions.loadUserRoleResourcesFailure({ error }));
      })
    )
  );
  private readonly addUserToResource$ = addUserToResourceFn();
  private readonly addUserToResources$ = addUserToResourcesFn();
  private readonly removeUserFromResource$ = removeUserFromResourceFn();
  private readonly openSuccessSnackBar = openSuccessSnackBarFn();
  private readonly openFailureSnackBar = openFailureSnackBarFn();
  private readonly translateService = inject(TranslateService);
  addToResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.addUserToResource),
      switchMap(({ userRoleResource }) =>
        this.addUserToResource$(userRoleResource).pipe(
          switchMap(data =>
            combineLatest([
              of(data),
              this.translateService.get('USER.ADD_SUCCESS'),
            ])
          ),
          tap(([, translation]) => this.openSuccessSnackBar(translation)),
          map(([res]) =>
            AuthActions.addUserToResourceSuccess({
              userRoleResources: res.data,
            })
          ),
          catchError(error => {
            return this.translateService.get('USER.ADD_FAILURE').pipe(
              tap(translation => this.openFailureSnackBar(translation)),
              map(() =>
                AuthActions.addUserToResourceFailure({
                  error: error.error.description,
                })
              )
            );
          })
        )
      ),
      finalize(() => {
        AuthActions.loadUserRoleResources();
      })
    )
  );

  addToResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.addUserToResources),
      switchMap(({ userRoleResources }) =>
        this.addUserToResources$(userRoleResources).pipe(
          switchMap(data =>
            combineLatest([
              of(data),
              this.translateService.get('USER.ADD_SUCCESS'),
            ])
          ),
          tap(([, translation]) => this.openSuccessSnackBar(translation)),
          map(([res]) =>
            AuthActions.addUserToResourcesSuccess({
              userRoleResources: res.data,
            })
          ),
          catchError(error => {
            return this.translateService.get('USER.ADD_FAILURE').pipe(
              tap(translation => this.openFailureSnackBar(translation)),
              map(() =>
                AuthActions.addUserToResourcesFailure({
                  error: error.error.description,
                })
              )
            );
          })
        )
      )
    )
  );
  removeFromResource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.removeUserFromResource),
      switchMap(({ user, project_id }) =>
        this.removeUserFromResource$(user, project_id).pipe(
          switchMap(data =>
            combineLatest([
              of(data),
              this.translateService.get('USER.REMOVE_SUCCESS'),
            ])
          ),
          tap(([, translation]) => this.openSuccessSnackBar(translation)),
          map(([res]) =>
            AuthActions.removeUserFromResourceSuccess({
              ids: res.data.map(val => val.id ?? ''),
            })
          ),
          catchError(error => {
            return this.translateService.get('USER.REMOVE_FAILURE').pipe(
              tap(translation => this.openFailureSnackBar(translation)),
              map(() =>
                AuthActions.addUserToResourcesFailure({
                  error: error.error.description,
                })
              )
            );
          })
        )
      )
    )
  );
}
