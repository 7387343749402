export interface IFilterOption {
  id: string;
  type: FilterOptionType;
}

export interface IFilterOptionText extends IFilterOption {
  type: 'text';
  placeholder: string;
}

interface IChip {
  name: string;
  translationString: string;
}

export interface IFilterOptionChip extends IFilterOption {
  type: 'chip';
  chips: IChip[];
}

type FilterOptionType = 'chip' | 'text';

export function isFilterOptionText(
  item: IFilterOption
): item is IFilterOptionText {
  return (item as IFilterOptionText).type === 'text';
}

export function isFilterOptionChip(item: unknown): item is IFilterOptionChip {
  return (
    (item as IFilterOptionChip).type === 'chip' &&
    !!(item as IFilterOptionChip).chips
  );
}
