import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

export const canActivateKeycloak = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const keycloakService = inject(KeycloakService);
  const isLoggedIn = await keycloakService.isLoggedIn();

  // Force the user to log in if currently unauthenticated.
  if (!isLoggedIn) {
    await keycloakService.login({
      redirectUri: window.location.origin + state.url,
    });
  }

  // Get the roles required from the route.
  const requiredRoles = route.data['roles'];

  // Allow the user to proceed if no additional roles are required to access the route.
  if (!Array.isArray(requiredRoles) || requiredRoles.length === 0) {
    return true;
  }

  // Allow the user to proceed if all the required roles are present.
  return requiredRoles.every(role =>
    keycloakService.getUserRoles().includes(role)
  );
};
