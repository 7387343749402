export function filterText<T>(
  filterValue: Record<string, unknown>,
  item: { title: string; description: string },
  controlname: string
): boolean {
  return (
    item.title.toLowerCase().includes(filterValue?.[controlname] as string) ||
    item.title.includes(filterValue?.[controlname] as string) ||
    item.description
      .toLowerCase()
      .includes(filterValue?.[controlname] as string) ||
    item.description.includes(filterValue?.[controlname] as string)
  );
}
