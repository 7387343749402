import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IVote } from '@feature-vote/boards/types';
import { APP_CONFIG } from '@feature-vote/app-config';

const domain = 'votes';

export const createVoteFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;
  return (vote: IVote) => {
    return client.post<{ data: IVote }>(url, vote);
  };
};

export const deleteVoteFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;
  return (id: IVote['id']) => {
    return client.delete<{ data: IVote['id'] }>(`${url}/${id}`);
  };
};
