import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LanguageService, TitleService } from '@feature-vote/shared/util';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'feature-vote-root',
  templateUrl: './app.component.html',
  providers: [LanguageService, TitleService],
})
export class AppComponent implements OnInit {
  constructor(private language: LanguageService, private title: TitleService) {}
  ngOnInit(): void {
    this.language.initLanguage();
  }
}
