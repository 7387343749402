import { IEnvironment } from '@feature-vote/shared-types';

export const environment: IEnvironment = {
  production: false,
  tinyApiKey: 'dm9w50k1t90m4l01sd8x9p3dl2n91e1kudb1sox18h4uct2t',
  apiUrl: 'https://api.dev.feature-vote.cheveo.de',
  keycloak: {
    url: 'https://identity.dev.marine.cheveo.de/',
    realm: 'feature-vote-dev',
    clientId: 'feature-vote-fe',
  },
};
