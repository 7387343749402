type HSL = [h: number, s: number, l: number];

export function colorFromStringInput(str = ''): string {
  const hash = getHash(str);
  const hsl = generateHSL(hash);
  return HSLtoString(hsl);
}

const hRange = [0, 360];
const sRange = [50, 100];
const lRange = [40, 70];

function getHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
}

function normalizeHash(hash: number, min: number, max: number) {
  return Math.floor((hash % (max - min)) + min);
}

function generateHSL(hash: number): HSL {
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
}

function HSLtoString(hsl: HSL) {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
}
