import { IDBBase } from '@feature-vote/shared-types';

export interface IUserRoleResource extends IDBBase {
  role_id: number;
  user_id: string;
  resource_id: string;
  resource_type_id: string;
}

export interface IAuth {
  roles: IRole[];
  userRoleResources: IUserRoleResource[];
}

export interface IRole {
  id: number;
  name: string;
}
