import { RequestError } from '@feature-vote/shared-types';

export function mappedErrorMessage(status_code: number): RequestError {
  switch (status_code) {
    case 403:
      return 'ERROR.FORBIDDEN';
    default:
      return 'ERROR.DEFAULT';
  }
}
