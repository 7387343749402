import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as BoardsActions from './boards.actions';
import * as BoardsSelectors from './boards.selectors';
import { IBoard } from '@feature-vote/boards/types';
import {
  deleteBoard,
  getConfidentialBoardAccess,
  getConfidentialBoardById,
  postSearchTags,
} from '../board.service';
import {
  createRequestTypeFn,
  deleteCommentFn,
  getConfidentialRequestTypeByIdFn,
  deleteRequestTypeFn,
  updateRequestTypeFn,
} from '../request-type.service';
import { createVoteFn, deleteVoteFn } from '../vote.service';
import { PaginationSearchObject } from '@feature-vote/shared-types';

@Injectable({ providedIn: 'root' })
export class BoardsFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(BoardsSelectors.selectBoardsLoaded));
  allBoards$ = this.store.pipe(select(BoardsSelectors.selectAllBoards));
  selectedBoard$ = this.store.pipe(select(BoardsSelectors.selectBoard));
  isLoading$ = this.store.pipe(select(BoardsSelectors.selectBoardsLoading));
  searchBoardHits$ = this.store.pipe(
    select(BoardsSelectors.selectSearchHitsBoards)
  );
  searchTagsHits$ = this.store.pipe(
    select(BoardsSelectors.selectSearchHitsTags)
  );
  isSearchLoading$ = this.store.pipe(
    select(BoardsSelectors.selectSearchLoading)
  );

  isTagsLoading$ = this.store.pipe(select(BoardsSelectors.selectTagsLoading));

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(projectId: string) {
    this.store.dispatch(BoardsActions.initBoards({ projectId }));
  }

  selectBoard(id: IBoard['id']) {
    this.store.dispatch(BoardsActions.selectBoardById({ id }));
  }

  createBoard(board: IBoard) {
    this.store.dispatch(BoardsActions.createBoard({ board }));
  }

  deleteBoard() {
    return deleteBoard();
  }

  createBoardSuccessfully(board: IBoard) {
    this.store.dispatch(BoardsActions.createBoardSuccess({ board }));
  }

  getBoardsByProjectId(projectId: string) {
    this.store.dispatch(BoardsActions.getBoardsByProjectId({ projectId }));
  }

  getAllBoards() {
    this.store.dispatch(BoardsActions.getAllBoards());
  }

  getSearchBoards(searchObject: PaginationSearchObject) {
    this.store.dispatch(BoardsActions.searchBoards({ searchObject }));
  }

  getSearchTags(searchObject: PaginationSearchObject) {
    this.store.dispatch(BoardsActions.searchTags({ searchObject }));
  }

  getSearchTagsFn() {
    return postSearchTags();
  }

  getConfidentialBoardById() {
    return getConfidentialBoardById();
  }

  getConfidentialBoardAccess() {
    return getConfidentialBoardAccess();
  }

  createRequestType() {
    return createRequestTypeFn();
  }

  deleteRequestType() {
    return deleteRequestTypeFn();
  }

  getConfidentialRequestTypeById() {
    return getConfidentialRequestTypeByIdFn();
  }

  updateRequestType() {
    return updateRequestTypeFn();
  }

  createVote() {
    return createVoteFn();
  }

  deleteVote() {
    return deleteVoteFn();
  }
  deleteComment() {
    return deleteCommentFn();
  }
}
