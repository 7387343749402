export function truncate(text: string, totalChars: number, endChars = 0) {
  endChars = Math.min(endChars, totalChars);
  const start = text.slice(0, totalChars - endChars);
  const end = endChars > 0 ? text.slice(-endChars) : '';

  if (start.length + end.length < text.length) {
    return start + '…' + end;
  } else {
    return text;
  }
}
