import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({ selector: '[featureVoteLoadingSpinner]', standalone: true })
export class LoadingSpinnerDirective {
  @Input() set isLoading(isLoading: boolean | null) {
    if (isLoading) {
      this.renderLoading();
    } else {
      this.removeLoading();
    }
  }

  private div: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.div = this.renderer.createElement('div');
    this.renderer.addClass(this.div, 'bg-gray-200');
    this.renderer.addClass(this.div, 'flex');
    this.renderer.addClass(this.div, 'justify-center');
    this.renderer.addClass(this.div, 'items-center');
    this.renderer.addClass(this.div, 'w-full');
    this.renderer.addClass(this.div, 'h-full');
    this.renderer.addClass(this.div, 'absolute');
    this.renderer.addClass(this.div, 'top-0');
    this.renderer.addClass(this.div, 'left-0');
    this.renderer.addClass(this.div, 'bg-opacity-75');
    this.renderer.addClass(this.div, 'animate-pulse');
    this.renderer.setProperty(this.div, 'innerHTML', 'Loading...');
  }

  private renderLoading(): void {
    this.renderer.appendChild(this.el.nativeElement, this.div);
  }

  private removeLoading(): void {
    this.renderer.removeChild(this.el.nativeElement, this.div);
  }
}
