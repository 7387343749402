import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromBoards from './+state/boards.reducer';
import { BoardsEffects } from './+state/boards.effects';
import { BoardsFacade } from './+state/boards.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromBoards.BOARDS_FEATURE_KEY,
      fromBoards.boardsReducer
    ),
    EffectsModule.forFeature([BoardsEffects]),
  ],
  providers: [BoardsFacade],
})
export class BoardsDataAccessModule {}
