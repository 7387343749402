import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';

@Directive({
  selector: '[featureVoteShowIfResourceOwner]',
  standalone: true,
})
export class ShowIfResourceOwnerDirective implements OnChanges {
  readonly #templateRef = inject(TemplateRef<unknown>);
  readonly #viewContainerRef = inject(ViewContainerRef);

  @Input({ required: true })
  featureVoteShowIfResourceOwner: boolean | null = false;

  ngOnChanges() {
    this.#onChanges();
  }

  #onChanges() {
    this.#viewContainerRef.clear();
    if (this.featureVoteShowIfResourceOwner) {
      this.#viewContainerRef.createEmbeddedView(this.#templateRef);
    }
  }
}
