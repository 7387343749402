import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { appRoutes } from './app.routes';
import { APP_CONFIG } from '@feature-vote/app-config';
import { initializeKeycloak } from '@feature-vote/keycloak';
import { environment } from '@feature-vote/app-config';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { AuthEffects, AuthFacade, authReducer } from '@feature-vote/auth/api';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorSnackbarInterceptorFn } from '@feature-vote/shared/util';

export const appConfig: ApplicationConfig = {
  providers: [
    provideStore(),
    provideState('auth', authReducer),
    provideEffects([AuthEffects]),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    provideRouter(appRoutes, withComponentInputBinding()),
    importProvidersFrom(KeycloakAngularModule),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([ErrorSnackbarInterceptorFn])
    ),
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [AuthFacade, KeycloakService, APP_CONFIG],
    },
    importProvidersFrom(MatSnackBarModule),
    provideAnimations(),
    importProvidersFrom(HttpClientModule), // or provideHttpClient() in Angular v15
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        extend: true,
      })
    ),
  ],
};
