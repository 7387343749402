import { createAction, props } from '@ngrx/store';
import {
  IAddUserToResource,
  IRole,
  IUserRoleResource,
} from '@feature-vote/auth/types';

export const initAuth = createAction('[Auth Page] Init');

export const loadRoles = createAction('[Auth/API] Load Roles');

export const loadRolesSuccess = createAction(
  '[Auth/API] Load Roles Success',
  props<{ roles: IRole[] }>()
);

export const loadRolesFailure = createAction(
  '[Auth/API] Load Roles Failure',
  props<{ error: any }>()
);

export const loadUserRoleResources = createAction(
  '[Auth/API] Load UserRoleResources'
);

export const loadUserRoleResourcesSuccess = createAction(
  '[Auth/API] Load UserRoleResources Success',
  props<{ userRoleResources: IUserRoleResource[] }>()
);

export const loadUserRoleResourcesFailure = createAction(
  '[Auth/API] Load UserRoleResources Failure',
  props<{ error: any }>()
);

export const setUserRoleResources = createAction(
  '[Auth/API] Set UserRoleResources',
  props<{ userRoleResources: IUserRoleResource[] }>()
);

export const setUserRoleResourcesSuccess = createAction(
  '[Auth/API] Set UserRoleResources Success',
  props<{ userRoleResources: IUserRoleResource[] }>()
);

export const addUserToResource = createAction(
  '[Auth/API] Add User to Resource',
  props<{ userRoleResource: IAddUserToResource }>()
);

export const addUserToResources = createAction(
  '[Auth/API] Add User to Resources',
  props<{ userRoleResources: IAddUserToResource[] }>()
);

export const addUserToResourceSuccess = createAction(
  '[Auth/API] Add User to Resource Success',
  props<{ userRoleResources: IUserRoleResource[] }>()
);

export const addUserToResourcesSuccess = createAction(
  '[Auth/API] Add User to Resources Success',
  props<{ userRoleResources: IUserRoleResource[] }>()
);

export const addUserToResourceFailure = createAction(
  '[Auth/API] Add User to Resource Failure',
  props<{ error: any }>()
);

export const addUserToResourcesFailure = createAction(
  '[Auth/API] Add User to Resource Failure',
  props<{ error: any }>()
);

export const removeUserFromResource = createAction(
  '[Auth/API] Remove User from Resource',
  props<{ user: IUserRoleResource; project_id: string }>()
);

export const removeUserFromResourceSuccess = createAction(
  '[Auth/API] Remove User from Resource Success',
  props<{ ids: string[] }>()
);

export const removeUserFromResourceFailure = createAction(
  '[Auth/API] Remove User from Resource Failure',
  props<{ error: any }>()
);
