import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, catchError, of, map } from 'rxjs';
import * as BoardsActions from './boards.actions';
import {
  getAllBoardsFn,
  getBoardsByProjectIdFn,
  postSearchBoards,
  postSearchTags,
} from '../board.service';

@Injectable()
export class BoardsEffects {
  private actions$ = inject(Actions);
  private readonly getBoardsByProjectIdFn$ = getBoardsByProjectIdFn();
  private readonly getAllBoardsFn$ = getAllBoardsFn();
  private readonly searchBoardsFn$ = postSearchBoards();
  private readonly searchTagsFn$ = postSearchTags();

  getBoardsByProjectId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.getBoardsByProjectId),
      switchMap(action =>
        this.getBoardsByProjectIdFn$(action.projectId).pipe(
          map(({ data }) => BoardsActions.loadBoardsSuccess({ boards: data }))
        )
      ),
      catchError(error => {
        console.error('Error', error);
        return of(BoardsActions.loadBoardsFailure({ error }));
      })
    )
  );

  getAllBoards = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.getAllBoards),
      switchMap(() =>
        this.getAllBoardsFn$().pipe(
          map(({ data }) => BoardsActions.loadBoardsSuccess({ boards: data }))
        )
      ),
      catchError(error => {
        console.error('Error', error);
        return of(BoardsActions.loadBoardsFailure({ error }));
      })
    )
  );

  searchBoards = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.searchBoards),
      switchMap(action =>
        this.searchBoardsFn$(action.searchObject).pipe(
          map(({ data }) => ({
            ...data,
            hits: data.hits.map(board => ({
              ...board,
              confidential:
                (board.confidential as unknown as string) === 'true',
            })),
          })),
          map(data => BoardsActions.searchBoardsSuccess({ boards: data }))
        )
      ),
      catchError(error => {
        console.error('Error', error);
        return of(BoardsActions.loadBoardsFailure({ error }));
      })
    )
  );

  searchTags = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.searchTags),
      switchMap(action =>
        this.searchTagsFn$(action.searchObject).pipe(
          map(({ data }) => ({
            ...data,
            hits: data.hits.map(tag => ({
              ...tag,
            })),
          })),
          map(data => BoardsActions.searchTagsSuccess({ tags: data }))
        )
      ),
      catchError(error => {
        console.error('Error', error);
        return of(BoardsActions.searchTagsFailure({ error }));
      })
    )
  );
}
