import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export const openSuccessSnackBarFn = () => {
  const snackBar = inject(MatSnackBar);

  return (message: string, duration = 4000) =>
    snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: ['success-snackbar'],
    });
};
export const openFailureSnackBarFn = () => {
  const snackBar = inject(MatSnackBar);
  return (message: string, duration = 4000) =>
    snackBar.open(message, 'OK', {
      duration: duration,
      panelClass: ['failure-snackbar'],
    });
};
