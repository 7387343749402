interface IKeycloak {
  url: string;
  realm: string;
  clientId: string;
}

export interface IEnvironment {
  production: boolean;
  tinyApiKey: string;
  apiUrl: string;
  keycloak: IKeycloak;
}
