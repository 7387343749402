import { createAction, props } from '@ngrx/store';
import { IBoard, IMeiliBoard, IMeiliTag } from '@feature-vote/boards/types';
import { Update } from '@ngrx/entity';
import {
  PaginationResult,
  PaginationSearchObject,
} from '@feature-vote/shared-types';

export const initBoards = createAction(
  '[Boards Page] Init',
  props<{ projectId: string }>()
);

export const loadBoardsSuccess = createAction(
  '[Boards/API] Load Boards Success',
  props<{ boards: IBoard[] }>()
);

export const loadBoardsFailure = createAction(
  '[Boards/API] Load Boards Failure',
  props<{ error: any }>()
);

export const createBoard = createAction(
  '[Boards/API] Create board',
  props<{ board: IBoard }>()
);

export const createBoardSuccess = createAction(
  '[Boards/API] Create board Success',
  props<{ board: IBoard }>()
);

export const createBoardFailure = createAction(
  '[Boards/API] Create board Failure',
  props<{ error: any }>()
);

export const selectBoardById = createAction(
  '[Boards/API] Select board By Id',
  props<{ id: IBoard['id'] }>()
);

export const getBoardsByProjectId = createAction(
  '[Boards/API] Get Boards By Project Id',
  props<{ projectId: string }>()
);

export const getAllBoards = createAction('[Boards/API] Get Boards');

export const updateBoardSuccess = createAction(
  '[Boards/API] Update board success',
  props<{ board: Update<IBoard> }>()
);

export const searchBoards = createAction(
  '[Boards/API] Search boards',
  props<{ searchObject: PaginationSearchObject }>()
);

export const searchBoardsSuccess = createAction(
  '[Boards/API] Search boards success',
  props<{ boards: PaginationResult<IMeiliBoard> }>()
);

export const searchBoardsFailure = createAction(
  '[Boards/API] Search boards failure',
  props<{ error: any }>()
);

export const searchTags = createAction(
  '[Boards/API] Search tags',
  props<{ searchObject: PaginationSearchObject }>()
);

export const searchTagsSuccess = createAction(
  '[Boards/API] Search tags success',
  props<{ tags: PaginationResult<IMeiliTag> }>()
);

export const searchTagsFailure = createAction(
  '[Boards/API] Search tags failure',
  props<{ error: any }>()
);
