import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
export const selectAuthState =
  createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const selectAuthLoaded = createSelector(
  selectAuthState,
  (state: AuthState) => state.loaded
);

export const selectAuthError = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
);

export const selectRoles = createSelector(
  selectAuthState,
  (state: AuthState) => state.roles
);

export const selectUserRoleResources = createSelector(
  selectAuthState,
  (state: AuthState) => state.userRoleResources
);

export const selectIsMaintainer = (user_id: string, resource_id: string) =>
  createSelector(
    selectAuthState,
    (state: AuthState) =>
      !!state.userRoleResources.find(
        userRoleResource =>
          userRoleResource.resource_id === resource_id &&
          userRoleResource.user_id === user_id &&
          userRoleResource.role_id === 1
      )
  );

export const selectLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.loading
);
