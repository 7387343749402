import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IBoard, IMeiliBoard, IMeiliTag } from '@feature-vote/boards/types';
import { APP_CONFIG } from '@feature-vote/app-config';
import {
  PaginationResult,
  PaginationSearchObject,
} from '@feature-vote/shared-types';

const domain = 'boards';

export const getBoards = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;

  return () => client.get<{ data: IBoard[] }>(url);
};

export const postSearchBoards = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}/search`;

  return (searchObject: PaginationSearchObject) =>
    client.post<{ data: PaginationResult<IMeiliBoard> }>(
      `${url}`,
      searchObject
    );
};

export const postSearchTags = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/tags/search`;

  return (searchObject: PaginationSearchObject) =>
    client.post<{ data: PaginationResult<IMeiliTag> }>(`${url}`, searchObject);
};

export const getConfidentialBoardAccess = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/confidential/${domain}/authorize`;

  return (id: IBoard['id'], password: string) => {
    const creds = ':' + password;
    const base64 = btoa(creds);
    const headers = {
      Authorization: `Basic ${base64}`,
    };
    return client.get<{ data: string }>(`${url}/${id}`, { headers });
  };
};

export const getConfidentialBoardById = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/confidential/${domain}`;

  return (id: IBoard['id']) => {
    const token = localStorage.getItem(id ?? '');

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return client.get<{ data: IBoard }>(`${url}/${id}`, { headers });
  };
};

export const getAllBoardsFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);

  return () => client.get<{ data: IBoard[] }>(`${config.apiUrl}/${domain}`);
};

export const getBoardsByProjectIdFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);

  return (projectId: string) =>
    client.get<{ data: IBoard[] }>(
      `${config.apiUrl}/projects/${projectId}/${domain}`
    );
};

export const createBoardFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/boards`;

  return (board: IBoard) => client.post<{ data: IBoard }>(url, board);
};

export const deleteBoard = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;

  return (id: IBoard['id']) =>
    client.delete<{ data: IBoard['id'] }>(`${url}/${id}`);
};
