import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@feature-vote/app-config';

export const checkIfUserExistsFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  return (email: string) => {
    const url = `${config.apiUrl}/users/check`;
    return client.get<boolean>(`${url}/${email}`);
  };
};
