import { createReducer, on, Action } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { IAuth, IRole, IUserRoleResource } from '@feature-vote/auth/types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState extends EntityState<IAuth> {
  selectedId?: string | number;
  roles: IRole[];
  userRoleResources: IUserRoleResource[];
  loaded: boolean;
  loading: boolean;
  error?: string | null;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const authAdapter: EntityAdapter<IAuth> = createEntityAdapter<IAuth>();

export const initialAuthState: AuthState = authAdapter.getInitialState({
  // set initial required properties
  roles: [],
  userRoleResources: [],
  loaded: false,
  loading: false,
});

const reducer = createReducer(
  initialAuthState,
  on(
    AuthActions.loadRoles,
    AuthActions.loadUserRoleResources,
    AuthActions.addUserToResource,
    AuthActions.addUserToResources,
    AuthActions.removeUserFromResource,
    state => ({
      ...state,
      loaded: false,
      loading: true,
      error: null,
    })
  ),
  on(AuthActions.loadRolesSuccess, (state, { roles }) => ({
    ...state,
    error: null,
    loading: false,
    loaded: true,
    roles,
  })),
  on(AuthActions.addUserToResourceSuccess, (state, { userRoleResources }) => ({
    ...state,
    error: null,
    loading: false,
    loaded: true,
    userRoleResources: [...state.userRoleResources.concat(userRoleResources)],
  })),
  on(AuthActions.addUserToResourcesSuccess, (state, { userRoleResources }) => ({
    ...state,
    error: null,
    loading: false,
    loaded: true,
    userRoleResources: [...state.userRoleResources.concat(userRoleResources)],
  })),
  on(
    AuthActions.loadRolesFailure,
    AuthActions.addUserToResourceFailure,
    AuthActions.addUserToResourcesFailure,
    AuthActions.removeUserFromResourceFailure,
    (state, { error }) => ({ ...state, error, loaded: false, loading: false })
  ),
  on(
    AuthActions.loadUserRoleResourcesSuccess,
    (state, { userRoleResources }) => ({
      ...state,
      error: null,
      loading: false,
      loaded: true,
      userRoleResources,
    })
  ),
  on(AuthActions.loadUserRoleResourcesFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false,
  })),
  on(AuthActions.removeUserFromResourceSuccess, (state, { ids }) => ({
    ...state,
    loaded: true,
    loading: false,
    userRoleResources: state.userRoleResources.filter(
      val => !ids.includes(val.id ?? '')
    ),
  }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
