import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@feature-vote/app-config';
import {
  IAddUserToResource,
  IRole,
  IUserRoleResource,
} from '@feature-vote/auth/types';
import { IUser } from '@feature-vote/boards/types';

const domain = 'roles';

export const getUsersByIdFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  return (ids: string[]) => {
    const url = `${config.apiUrl}/users-by-id?`;

    return client.get<{ data: IUser[] }>(
      ids.reduce((acc, val) => acc + `id=${val}&`, url)
    );
  };
};

export const getRolesFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;

  return () => client.get<{ data: IRole[] }>(url);
};

export const getUserRoleResourcesFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/user-role-resources`;

  return () => client.get<{ data: IUserRoleResource[] }>(url);
};

export const setUserRoleResourcesFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/user-role-resources`;

  return (userRoleResources: IUserRoleResource[]) =>
    client.post<{ data: IUserRoleResource[] }>(url, userRoleResources);
};

export const addUserToResourceFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);

  const url = `${config.apiUrl}`;
  return (data: IAddUserToResource) =>
    client.put<{ data: IUserRoleResource[] }>(
      `${url}/user-role-resources/add-user-to-resource?type=${data.resource_type}`,
      data
    );
};

export const addUserToResourcesFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);

  const url = `${config.apiUrl}`;
  return (data: IAddUserToResource[]) =>
    client.put<{ data: IUserRoleResource[] }>(
      `${url}/user-role-resources/add-user-to-resources?type=${'board'}`,
      data
    );
};

export const removeUserFromResourceFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/user-role-resources/remove-user-from-resource`;

  return (UserRoleResource: IUserRoleResource, project_id: string) =>
    client.delete<{ data: IUserRoleResource[] }>(
      `${url}?type=${UserRoleResource.resource_type_id}&resource-id=${UserRoleResource.resource_id}&user-id=${UserRoleResource.user_id}&project-id=${project_id}`
    );
};
