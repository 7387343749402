import { ILanguage } from './language.interface';

export const LanguageConstant: ILanguage[] = [
  {
    name: 'en',
  },
  {
    name: 'de',
  },
];
