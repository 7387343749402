import { Directive, HostBinding, Input } from '@angular/core';

type Status = 'OPEN' | 'ACCEPTED' | 'IN_PROGRESS' | 'DONE' | 'REJECTED';

@Directive({ selector: '[featureVoteColorStatus]', standalone: true })
export class ColorStatusDirective {
  protected _elementClass: string[] = [];

  @Input() set featureVoteColorStatus(status: Status) {
    switch (status) {
      case 'OPEN':
        this._elementClass = [
          'text-yellow-600',
          'bg-yellow-50',
          'p-2',
          'rounded-lg',
        ];
        break;
      case 'ACCEPTED':
        this._elementClass = [
          'text-teal-600',
          'bg-teal-50',
          'p-2',
          'rounded-lg',
        ];
        break;
      case 'DONE':
        this._elementClass = [
          'text-green-600',
          'bg-green-50',
          'p-2',
          'rounded-lg',
        ];
        break;
      case 'IN_PROGRESS':
        this._elementClass = [
          'text-blue-600',
          'bg-blue-50',
          'p-2',
          'rounded-lg',
        ];
        break;
      case 'REJECTED':
        this._elementClass = ['text-red-600', 'bg-red-50', 'p-2', 'rounded-lg'];
        break;
      default:
        break;
    }
  }

  @HostBinding('class')
  get elementClass(): string {
    return this._elementClass.join(' ');
  }
  set(val: string) {
    this._elementClass = val.split(' ');
  }
}
