import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BOARDS_FEATURE_KEY,
  BoardsState,
  boardsAdapter,
} from './boards.reducer';

// Lookup the 'Boards' feature state managed by NgRx
export const selectBoardsState =
  createFeatureSelector<BoardsState>(BOARDS_FEATURE_KEY);

const { selectAll, selectEntities } = boardsAdapter.getSelectors();

export const selectBoardsLoaded = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.loaded
);

export const selectSearchHitsBoards = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.searchBoardHits
);

export const selectSearchLoading = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.loadingSearch
);

export const selectSearchHitsTags = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.searchTagHits
);

export const selectTagsLoading = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.loadingTags
);

export const selectBoardsError = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.error
);

export const selectAllBoards = createSelector(
  selectBoardsState,
  (state: BoardsState) => selectAll(state) || []
);

export const selectBoardsEntities = createSelector(
  selectBoardsState,
  (state: BoardsState) => selectEntities(state)
);

export const selectSelectedId = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.selectedId
);

export const selectBoard = createSelector(
  selectBoardsEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const selectBoardsLoading = createSelector(
  selectBoardsState,
  (state: BoardsState) => state.loading
);
