export interface IDBBase {
  id?: string;
  created_at?: string;
  deleted_at?: string;
  updated_at?: string;
}

export type WithoutDBKeys = Omit<
  IDBBase,
  'created_at' | 'deleted_at' | 'updated_at'
>;
export type DBKeys = keyof IDBBase;
