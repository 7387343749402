import { AbstractControl, ValidationErrors } from '@angular/forms';
import { map, Observable } from 'rxjs';

export const createCustomValidator = (
  control: AbstractControl,
  checkFunction: (...args: any[]) => Observable<boolean>
): Observable<ValidationErrors | null> => {
  return checkFunction(control.value).pipe(
    map(res => (!res ? { exists: true } : null))
  );
};
