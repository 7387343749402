import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LanguageService } from './language.service';
import { combineLatest, filter, map } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { extractTitleFromUrl } from './title.function';

@Injectable({ providedIn: 'root' })
export class TitleService {
  constructor(
    public translate: TranslateService,
    public titleService: Title,
    public language: LanguageService,
    private readonly router: Router
  ) {
    combineLatest([
      this.translate.onLangChange,
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => (event as NavigationEnd).url)
      ),
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([t, d]) =>
        this.titleService.setTitle(
          t.translations['TITLE'][extractTitleFromUrl(d)]
        )
      );
  }

  setCurrentTitle(event: LangChangeEvent, title: string) {
    return this.titleService.setTitle(title);
  }
}
