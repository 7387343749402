import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  IBoard,
  IComment,
  IRequestType,
  RequestTypeWithoutDBKeys,
} from '@feature-vote/boards/types';
import { APP_CONFIG } from '@feature-vote/app-config';

export type GetConfidentialRequestTypeFetchData = {
  boardId: IBoard['id'];
  requestTypeId: IRequestType['id'];
};

const domain = 'request-types';

export const getConfidentialRequestTypeByIdFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/confidential/${domain}`;
  return ({ boardId, requestTypeId }: GetConfidentialRequestTypeFetchData) => {
    const token = localStorage.getItem(boardId ?? '');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return client.get<{ data: IRequestType }>(`${url}/${requestTypeId}`, {
      headers,
    });
  };
};

export const createConfidentialRequestTypeFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/confidential/${domain}`;
  return (requestType: Omit<RequestTypeWithoutDBKeys, 'votes'>) => {
    const token = localStorage.getItem(requestType.board_id ?? '');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return client.post<{ data: IRequestType }>(url, requestType, { headers });
  };
};

export const createRequestTypeFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;
  return (requestType: Omit<RequestTypeWithoutDBKeys, 'votes' | 'status'>) => {
    return client.post<{ data: IRequestType }>(url, requestType);
  };
};

export const updateRequestTypeFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;
  return (requestType: Omit<IRequestType, 'votes' | 'comments'>) => {
    return client.put<{ data: IRequestType }>(
      `${url}/${requestType.id}`,
      requestType
    );
  };
};

export const deleteRequestTypeFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;
  return (requestType: IRequestType) => {
    return client.delete<{ data: IRequestType }>(
      `${url}/${requestType.id}?boardId=${requestType.board_id}`
    );
  };
};

export const updateVoteFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/${domain}`;
  return (requestType: IRequestType) => {
    return client.put<{ data: IRequestType }>(
      `${url}/${requestType.id}`,
      requestType
    );
  };
};

export const createCommentFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/comments`;
  return (payload: Pick<IComment, 'comment' | 'request_type_id'>) => {
    return client.post<{ data: IComment }>(url, payload);
  };
};

export const deleteCommentFn = () => {
  const client = inject(HttpClient);
  const config = inject(APP_CONFIG);
  const url = `${config.apiUrl}/comments`;
  return (id: IComment['id']) => {
    return client.delete<{ data: IComment['id'] }>(`${url}/${id}`);
  };
};
