import { Injectable, inject } from '@angular/core';
import * as AuthActions from '../+state/auth.actions';
import { Store, select } from '@ngrx/store';
import {
  IAddUserToResource,
  IRole,
  IUserRoleResource,
} from '@feature-vote/auth/types';
import * as AuthSelectors from '../+state/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  private readonly store = inject(Store);

  roles$ = this.store.pipe(select(AuthSelectors.selectRoles));
  userRoleResources$ = this.store.pipe(
    select(AuthSelectors.selectUserRoleResources)
  );
  isLoading$ = this.store.pipe(select(AuthSelectors.selectLoading));
  authError$ = this.store.pipe(select(AuthSelectors.selectAuthError));
  isMaintainer$ = (user_id: string, resource_id: string) =>
    this.store.pipe(
      select(AuthSelectors.selectIsMaintainer(user_id, resource_id))
    );

  getRoles() {
    this.store.dispatch(AuthActions.loadRoles());
  }

  getRolesSuccess(roles: IRole[]) {
    this.store.dispatch(AuthActions.loadRolesSuccess({ roles }));
  }

  getRolesFailure(error: any) {
    this.store.dispatch(AuthActions.loadRolesFailure({ error }));
  }

  getUserRoleResource() {
    this.store.dispatch(AuthActions.loadUserRoleResources());
  }

  getUserRoleResourceSuccess(userRoleResources: IUserRoleResource[]) {
    this.store.dispatch(
      AuthActions.loadUserRoleResourcesSuccess({ userRoleResources })
    );
  }

  getUserRoleResourceFailure(error: any) {
    this.store.dispatch(AuthActions.loadUserRoleResourcesFailure({ error }));
  }

  addUserToResource(userRoleResource: IAddUserToResource) {
    this.store.dispatch(AuthActions.addUserToResource({ userRoleResource }));
  }

  addUserToResources(userRoleResources: IAddUserToResource[]) {
    this.store.dispatch(AuthActions.addUserToResources({ userRoleResources }));
  }

  addUserToResourceSuccess(userRoleResources: IUserRoleResource[]) {
    this.store.dispatch(
      AuthActions.addUserToResourceSuccess({ userRoleResources })
    );
  }

  addUserToResourceFailure(error: any) {
    this.store.dispatch(AuthActions.addUserToResourceFailure({ error }));
  }
  removeUserFromResource(user: IUserRoleResource, project_id: string) {
    this.store.dispatch(
      AuthActions.removeUserFromResource({ user, project_id })
    );
  }

  removeUserFromResourceFailure(error: any) {
    this.store.dispatch(AuthActions.removeUserFromResourceFailure({ error }));
  }
}
