import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as BoardsActions from './boards.actions';
import { IBoard, IMeiliBoard, IMeiliTag } from '@feature-vote/boards/types';
import { PaginationResult } from '@feature-vote/shared-types';

export const BOARDS_FEATURE_KEY = 'boards';

export interface BoardsState extends EntityState<IBoard> {
  selectedId?: string | number;
  loaded: boolean;
  loading: boolean;
  error?: string | null;
  searchBoardHits: PaginationResult<IMeiliBoard>;
  loadingSearch: boolean;
  searchTagHits: PaginationResult<IMeiliTag>;
  loadingTags: boolean;
}

export interface BoardsPartialState {
  readonly [BOARDS_FEATURE_KEY]: BoardsState;
}

export const boardsAdapter: EntityAdapter<IBoard> =
  createEntityAdapter<IBoard>();

export const initialBoardsState: BoardsState = boardsAdapter.getInitialState({
  loaded: false,
  loading: false,
  searchBoardHits: {} as PaginationResult<IMeiliBoard>,
  loadingSearch: false,
  searchTagHits: { hits: [] as IMeiliTag[] } as PaginationResult<IMeiliTag>,
  loadingTags: false,
});

const reducer = createReducer(
  initialBoardsState,
  on(BoardsActions.initBoards, BoardsActions.getAllBoards, state => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(BoardsActions.selectBoardById, (state, { id }) => {
    return { ...state, selectedId: id };
  }),
  on(BoardsActions.loadBoardsSuccess, (state, { boards }) =>
    boardsAdapter.setAll(boards, { ...state, loaded: true, loading: false })
  ),
  on(BoardsActions.loadBoardsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(BoardsActions.createBoardSuccess, (state, { board }) =>
    boardsAdapter.setOne(board, { ...state, loaded: true, loading: false })
  ),
  on(BoardsActions.updateBoardSuccess, (state, { board }) =>
    boardsAdapter.updateOne(board, { ...state, loaded: true, loading: false })
  ),
  on(BoardsActions.searchBoards, state => ({
    ...state,
    searchBoardHits: {
      ...state.searchBoardHits,
      hits: [],
      estimatedTotalHits: 0,
      totalHits: 0,
      limit: 0,
    },
    loadingSearch: true,
  })),
  on(BoardsActions.searchBoardsSuccess, (state, { boards }) => ({
    ...state,
    loadingSearch: false,
    searchBoardHits: boards,
  })),
  on(BoardsActions.searchTags, state => ({
    ...state,
    searchTagHits: {
      ...state.searchTagHits,
      hits: [],
      estimatedTotalHits: 0,
      totalHits: 0,
      limit: 0,
    },
    loadingTags: true,
  })),
  on(BoardsActions.searchTagsSuccess, (state, { tags }) => ({
    ...state,
    loadingTags: false,
    searchTagHits: tags,
  }))
);

export function boardsReducer(state: BoardsState | undefined, action: Action) {
  return reducer(state, action);
}
