import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { AuthFacade } from '@feature-vote/auth/api';

export function initializeKeycloak(
  authFacade: AuthFacade,
  keycloak: KeycloakService,
  config: { keycloak: { url: string; realm: string; clientId: string } }
) {
  keycloak.keycloakEvents$.pipe().subscribe({
    next(event) {
      if (event.type == KeycloakEventType.OnAuthSuccess) {
        authFacade.getRoles();
        authFacade.getUserRoleResource();
      }
    },
  });

  return () =>
    keycloak.init({
      config: config.keycloak,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
      },
    });
}
