import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LanguageConstant } from '@feature-vote/shared-types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  languages: string[] = LanguageConstant.map(value => value.name);
  destroy = takeUntilDestroyed<LangChangeEvent>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    public translate: TranslateService,
    public renderer: Renderer2
  ) {
    this.translate.onLangChange
      .pipe(this.destroy)
      .subscribe(() => this.setLanguageProperty('lang'));
  }

  initLanguage() {
    this.translate.addLangs(this.languages);
    this.translate.setDefaultLang('en');
    const savedLanguage = this.getLanguage();
    if (savedLanguage) {
      this.translate.use(savedLanguage ?? 'en');
    } else {
      const browserLang = this.translate.getBrowserLang();
      const matchedLang = browserLang?.match(/en|de/) ? browserLang : 'en';
      this.translate.use(matchedLang);
      this.saveLanguage(matchedLang);
    }
  }

  setLanguageProperty(lang: string) {
    const html = this.document.querySelector('html');
    this.renderer.setAttribute(html, lang, this.translate.currentLang);
  }

  saveLanguage(lang: string) {
    localStorage.setItem('language', lang);
  }
  getLanguage() {
    return localStorage.getItem('language');
  }
}
