import { inject } from '@angular/core';
import {
  HttpRequest,
  HttpErrorResponse,
  HttpHandlerFn,
} from '@angular/common/http';
import { catchError } from 'rxjs';
import { openFailureSnackBarFn } from './snackbar.functions';
import { TranslateService } from '@ngx-translate/core';

export function ErrorSnackbarInterceptorFn(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const openFailureSnackBar = openFailureSnackBarFn();
  const translateService = inject(TranslateService);

  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err.status == 0 || err.statusText == 'Unknown Error') {
        translateService
          .get('ERROR.DEFAULT')
          .subscribe(translation => openFailureSnackBar(translation));
      }
      throw err;
    })
  );
}
