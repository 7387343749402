export function extractTitleFromUrl(url: string) {
  if (url === '/') {
    return 'DEFAULT';
  } else if (url.includes('boards')) {
    return 'BOARDS';
  } else {
    return isSingleWord(url)
      ? url.substring(1).toUpperCase()
      : url.substring(1, url.indexOf('/', 1)).toUpperCase();
  }
}

function isSingleWord(url: string) {
  return url.indexOf('/', 1) === -1 ? true : false;
}
